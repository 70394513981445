@use '@/styles/utils/mixins.scss' as *;

.productWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  @include tab() {
    gap: 3.75rem;
  }
  .sectionHeadingContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    width: 100%;
    @include tab-des() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .sectionLeftContent {
    grid-column: span 2;
    @include tab-des() {
      grid-column: span 1;
    }
  }
  .sectionDescription {
  }
  .categoryLists {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include tab() {
      gap: 3.75rem;
    }
  }
}
